import { CSSObject, colors, ResponsiveValue } from "@biblioteksentralen/js-utils";
import { DecorativeImage, ImageWithMetadata } from "@libry-content/types";
import { LocalizedField } from "@libry-content/localization";
import Image from "next/image";
import React from "react";
import { imageUrlBuilder } from "@libry-content/common";
import { Modify } from "@libry-content/types";
import { Box } from "@chakra-ui/layout";
import { css } from "@emotion/react";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { safelyGetImageDimensions } from "./safelyGetImageDimensions";

const Placeholder = (props: { aspectRatio: number; borderRadius?: ResponsiveValue<string>; background?: string }) => (
  <Box
    width="100%"
    css={css`
      aspect-ratio: ${props.aspectRatio};
    `}
    background={props.background ?? `linear-gradient(270deg, ${colors.grey15}, ${colors.grey30})`}
    borderRadius={props.borderRadius ?? "xl"}
  />
);

export type GalleryImage = Omit<ImageWithMetadata, "_type"> & {
  _type: "image";
  title?: LocalizedField<string>;
  description?: LocalizedField<string>;
};

export type SanityImageSrc = ImageWithMetadata | DecorativeImage | GalleryImage;

type Props = Modify<
  React.ComponentProps<typeof Image>,
  {
    borderRadius?: ResponsiveValue<string>;
    width?: number;
    height?: number;
    src?: SanityImageSrc;
    hideCaption?: boolean;
    quality?: number;
    blurQuality?: number;
    _groupHover?: CSSObject;
    background?: string;
    border?: string;
    alt?: string;
  }
>;

// This component both uses next image for lazy loading and other next-opimizations, and calls sanity with image dimensions to utilize image hotspot and reduce network trafic 🍀
const SanityImage = (props: Props) => {
  const {
    src,
    borderRadius,
    hideCaption,
    quality = 85,
    blurQuality = 70,
    background = `linear-gradient(135deg, ${colors.grey05}, ${colors.grey15})`,
    _groupHover = { transform: "scale(1.03)" },
    border,
    ...rest
  } = props;
  const { t, ts } = useTranslation();

  const aspectRatio = props.width && props.height ? props.width / props.height : 0.75;

  if (!src?.asset) {
    return <Placeholder aspectRatio={aspectRatio} borderRadius={borderRadius} background={background} />;
  }

  const urlBuilder = imageUrlBuilder(src)?.quality(quality);
  const sourceWidth = safelyGetImageDimensions(src).width;

  const imgUrl =
    props.height && props.width
      ? urlBuilder?.size(props.width, props.height).url() // Sørger for at vi respekterer hotspot
      : urlBuilder?.width(Math.min(1080, sourceWidth)).url(); // Må gi en width her, hvis ikke nedskaleres ikke bilder, og vi kan laste in bilder i enorm størresle
  const blurImageUrl =
    imageUrlBuilder(src)
      ?.size(10, Math.round(10 / aspectRatio))
      .format("webp")
      .quality(blurQuality)
      .url() ?? undefined;

  if (!imgUrl) {
    console.warn("Kunne ikke bygge url for bilde", src);
    return <Placeholder aspectRatio={aspectRatio} borderRadius={borderRadius} background={background} />;
  }

  return (
    <Box as="figure" height="100%">
      {/* Måtte ha wrapper for at blur/placeholder også skal få border-radius */}
      <Box
        background={background}
        overflow="hidden"
        isolation="isolate" // bugfix-safari - problem med overflow i kombinasjon med scale-animasjon https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
        height="100%"
        borderRadius={borderRadius ?? "xl"}
        border={border}
        css={css`
          img {
            transition: filter 0.05s; // speed of blur-fade
          }
        `}
      >
        <Box _groupHover={_groupHover} transition=".5s">
          <Image
            src={imgUrl}
            blurDataURL={blurImageUrl}
            placeholder={blurImageUrl ? "blur" : undefined}
            alt={(isImageWithMetadata(src) && src.altText?.enabled && ts(src.altText?.text)) || ""}
            {...rest}
          />
        </Box>
      </Box>
      {isImageWithMetadata(src) && src.creator && !hideCaption && (
        <Box fontStyle="italic" textAlign="end" marginRight="0.1rem" fontSize="sm" opacity={0.8}>
          {t("Foto: {opphavsPerson}", { opphavsPerson: src.creator })}
        </Box>
      )}
    </Box>
  );
};

export const isImageWithMetadata = (image: Props["src"]): image is ImageWithMetadata => {
  return image?._type === "imageWithMetadata";
};

export default SanityImage;
